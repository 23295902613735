import React from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { THEME } from '@constants/base';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import { store } from '@store/index';
import I18n from '@utils/i18n';
import { MemoOauthLogin } from '@widget/login/rightSide';

import styles from './index.module.less';

const LoginChannelList = [
  { btnText: 'Apple', src: require('@images/icon/appleIcon.webp'), darkSrc: require('@images/icon/darkAppleIcon.webp') },
  { btnText: 'Facebook', src: require('@images/icon/facebookIcon.webp'), darkSrc: require('@images/icon/facebookIcon.webp') },
  { btnText: 'Google', src: require('@images/icon/googleIcon.webp'), darkSrc: require('@images/icon/googleIcon.webp') }
];
const auth = require('firebase/auth');

const getProvider = (type) => {
  let provider;
  if (type === 'Apple') {
    provider = new auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
  } else if (type === 'Facebook') {
    provider = provider = new auth.FacebookAuthProvider();
    provider.addScope('email');
  } else {
    provider = new auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
  }
  return provider;
};
interface OtherLoginProps {
  openLoginVerifyModal: (props: any) => Promise<any>;
  handleErrMsg: (value: any) => void;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
  /** 支持三方登录的列表 不传则不支持  */
  oAuthList?: string[];
  /** 三方登录的形式 popup 弹出式 redirect 重定向式 */
  mode?: 'popup'|'redirect';
  loginSuccess?: (params: {res: any}) => void;
  /** 主题 */
  theme?: Theme;
  checkWalletBind:(userInfo: any)=>void
}

export const OtherLogin: React.FC<OtherLoginProps> = observer((props) => {
  const { oAuthList, mode, theme, checkWalletBind } = props;
  const router = useRouter();
  // 三方登录 3
  const loginOAuth = async (user) => {
    return new Promise<{ success: boolean; obj: Record<string, string> }>(
      (resolve, reject) => {
        store.userStore.doLoginOauth(
          {
            idToken: user.accessToken
          },
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      }
    );
  };
  // 三方登录 2
  const quickLogin = async (params?: any) => {
    try {
      let result = params;
      if (!result) {
        const _auth = auth.getAuth();
        result = await auth.getRedirectResult(_auth);
      }
      if (!result) {
        return;
      }
      const user = result.user;
      const res = await loginOAuth(user);

      const useInfo = res?.obj;

      await checkWalletBind(useInfo)
      // return
      // if (!res.success) {
      //   // 2. 非常用设备 二次验证
      //   const { isEmailCertified, isGoogleCertified, isPhoneCertified } =
      //     res.obj;
      //   useInfo = await props.openLoginVerifyModal({
      //     safetyStore: store.getSafetyStore,
      //     isBindEmail: isEmailCertified,
      //     isBindPhone: isPhoneCertified,
      //     isBindGoogle: isGoogleCertified
      //   });
      //   store.userStore.loginSuccess(useInfo);
      // }
      // loginSuccess?.({ res: useInfo })
      // await props.getUserSocialInfo(useInfo);
    } catch (error) {
      props.handleErrMsg(error);
    }
  };

  // 三方重定向登录 1
  const triggerLogin = async (type) => {
    const _auth = auth.getAuth();
    const provider = getProvider(type);
    if (mode === 'redirect') {
      auth.signInWithRedirect(_auth, provider);
      return
    }
    const res = await auth.signInWithPopup(_auth, provider)
    quickLogin(res);
  };

  useIsomorphicLayoutEffect(() => {
    quickLogin();
    if (!router) return;
    const tab = router.query?.tab;
    tab && props.setActiveKey(tab as string);
  }, []);
  if (!oAuthList.length) {
    return null;
  }
  return (
    <>
      <div className={styles.otherLoginBox}>
        <div className={styles.otherLoginLine} />
        <div className={cn(styles.otherLoginText, {
          [styles.darkOtherLoginText]: theme === THEME.DARK
        })}>
          {I18n.t('Other.login.methods')}
        </div>
        <div className={styles.otherLoginLine} />
      </div>
      <div className={styles.oAuthLogin}>
        {LoginChannelList.filter(item => oAuthList.includes(item.btnText)).map((item) => (
          <MemoOauthLogin
            data-buried="login"
            data-buried-type={`login-${item.btnText}`}
            key={item.btnText}
            theme={theme}
            btnText={item.btnText}
            src={theme === THEME.DARK ? item.darkSrc : item.src}
            onClick={() => triggerLogin(item.btnText)}
          />
        ))}
      </div>
    </>
  );
});
