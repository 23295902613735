import { useLoginAuth as useWalletLoginAuth, useOauthNonce } from 'api-hooks'
import { useEOAWalletConnectKit } from 'particle-connect';

export const useLoginAuth = ({ getAddress, getChainName, getSignture }) => {
  const mutationOauthNonce = useOauthNonce(null);
  const mutationLoginAuth = useWalletLoginAuth(null);
  const { getWalletSignture, getWalletChainName } = useEOAWalletConnectKit();

  const login = async ({ loginType = 'eoa', publicAddress = '', provider = null }) => {
    const address = publicAddress || (await getAddress())
    const publicKey = address

    // 1.get nonce

    let nonce
    try {
      const params: any = { publicKey }
      const data = await mutationOauthNonce.mutateAsync(params)
      nonce = data.data.nonce
      console.error('nonce', nonce)
    } catch (error) {
      console.error(error)
      return {
        userInfo: null,
        error: error.message
      }
    } finally {
      console.log('done')
    }

    // 2.wallet login

    try {
      const walletType = loginType === 'eoa' ? await getWalletChainName(provider) : await getChainName()
      let signature = ''
      if (loginType === 'eoa') {
        signature = await getWalletSignture(provider, {
          nonce, publicKey, walletType
        }, address)
      } else {
        signature = await getSignture({
          nonce, publicKey, walletType
        }, address)
      }

      const loginAuthQueryParams: any = {
        publicKey,
        walletType,
        nonce,
        signature,
        platform: 'particle'
      }
      const { data }: any = await mutationLoginAuth.mutateAsync(loginAuthQueryParams)
      console.error('loginAuth', data)
      if (data.success) {
        return {
          userInfo: data.obj,
          error: null
        }
      } else {
        return {
          userInfo: null,
          error: data.msgKey
        }
      }
    } catch (error) {
      console.error(error)
      return {
        userInfo: null,
        error: error.message
      }
    } finally {
      console.log('done')
    }
  }
  return {
    login
  }
}
